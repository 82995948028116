import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import PerfectScrollbar from 'react-perfect-scrollbar';
import Nav from '../AppNav/VerticalNavWrapper';
import HeaderLogo from '../AppLogo';

import {
  setEnableMobileMenu,
} from '../../reducers/ThemeOptions';

const AppSidebar = () => {
  const {
    backgroundColor,
    enableBackgroundImage,
    enableSidebarShadow,
    enableMobileMenu,
    backgroundImage,
    backgroundImageOpacity,
  } = useSelector((state) => state.ThemeOptions);

  const dispatch = useDispatch();

  const toggleMobileSidebar = () => {
    dispatch(setEnableMobileMenu(!enableMobileMenu));
  };

  return (
    <>
      <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar} />
      <ReactCSSTransitionGroup
        component="div"
        className={cx('app-sidebar', backgroundColor, { 'sidebar-shadow': enableSidebarShadow })}
        transitionName="SidebarAnimation"
        transitionAppear
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <HeaderLogo />
        <PerfectScrollbar>
          <div className="app-sidebar__inner">
            <Nav />
          </div>
        </PerfectScrollbar>
        <div
          className={cx('app-sidebar-bg', backgroundImageOpacity)}
          style={{
            backgroundImage: enableBackgroundImage ? `url(${backgroundImage})` : null,
          }}
        />
      </ReactCSSTransitionGroup>
    </>
  );
};

export default AppSidebar;
