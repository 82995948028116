import dataSource from "../data/nCoVi-19_VN.csv";
import dailyDataSource from "../data/nCoVi-19_VN - Dien bien ngay.csv";
import pieDataSource from "../data/PieChartNcov.csv";
import { getCsvData } from "../utils/helpers";

export const getIcon = (sex, age) => {
  const sexIndex = sex === "Nam" ? 1 : 0;
  // eslint-disable-next-line no-nested-ternary
  const ageIndex = age < 20 ? 2 : age <= 50 ? 3 : 4;

  return `${process.env.PUBLIC_URL}/${sexIndex}-${ageIndex}.png`;
};

export const mergeDuplicateKeys = (data, key, duplicates) => {
  const masterValue = duplicates[0];

  return data.reduce(
    (accumulator, node) => [
      ...accumulator,
      {
        ...node,
        [key]: duplicates.includes(node[key]) ? masterValue : node[key],
      },
    ],
    []
  );
};

const getSingleton = (source) => {
  let masterData;

  const init = async () => {
    masterData = await getCsvData(source);
  };

  return {
    getData: async () => {
      if (!masterData) {
        await init();
      }
      return masterData;
    },
    init,
  };
};

const handleError = () => {
  window.alert(
    "Trang web hiện không khả dụng và vấn đề đang được khắc phục ngay lúc này. Chúng tôi rất xin lỗi vì sự cố."
  );
};

const endpoints = {
  local: "http://localhost:8080",
  heroku: "https://pacific-cliffs-07599.herokuapp.com",
  gsbenh: "https://ncov.gsbenh.vn/ss",
};
export const getSpreadsheetData = async (location) => {
  const response = await fetch(
    `${endpoints.heroku}/spreadsheets${
      location ? `?location=${location}` : ""
    }`,
    {
      "Content-Type": "application/json",
      method: "GET",
    }
  );

  if (response.status > 300) {
    handleError();
  }

  return response.json();
};

export const getCasesData = async () => {
  const response = await fetch(
    "https://api.apify.com/v2/key-value-stores/EaCBL1JNntjR3EakU/records/LATEST?disableRedirect=true",
    {
      "Content-Type": "application/json",
      method: "GET",
    }
  );

  return response.json();
};

const getSpreadsheetSingleton = () => {
  let masterData;

  const init = async () => {
    masterData = await getSpreadsheetData();
  };

  return {
    getData: async () => {
      if (!masterData) {
        await init();
      }
      return masterData;
    },
    init,
  };
};

export const MasterData = getSingleton(dataSource);

export const DailyData = getSingleton(dailyDataSource);

export const PieData = getSingleton(pieDataSource);

export const NetworkData = getSpreadsheetSingleton();

export const getTotalStats = async () => {
  const masterData = await MasterData.getData();
  return {
    current: masterData.filter((elm) => elm["Tình trạng"] === "Đang điều trị")
      .length,
    death: masterData.filter((elm) => elm["Tình trạng"] === "Tử vong").length,
    recovered: masterData.filter((elm) => elm["Tình trạng"] === "Khỏi").length,
    total: masterData.length,
  };
};

export const getSetOfKey = (sourceData, key, emptyKey = undefined) => {
  let hasEmptyKey = false;
  const set = new Set();
  sourceData.forEach((entry) => {
    if (!set.has(entry[key]) && (emptyKey || entry[key] !== "")) {
      if (entry[key] === "") {
        hasEmptyKey = true;
      }
      set.add(entry[key]);
    }
  });
  if (hasEmptyKey && emptyKey) {
    set.add(emptyKey);
  }
  return set;
};
