import React from 'react';
import ReactDOM from 'react-dom';
// import registerServiceWorker from './registerServiceWorker';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { unregister } from './registerServiceWorker';

import Main from './DemoPages/Main';
import configureStore from './config/configureStore';
import { NetworkDataProvider } from './components/providers/networkDataProvider';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './assets/base.css';

const store = configureStore();
const rootElement = document.getElementById('root');

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <NetworkDataProvider>
          <Component />
        </NetworkDataProvider>
      </HashRouter>
    </Provider>,
    rootElement,
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept('./DemoPages/Main', () => {
    const NextApp = import('./DemoPages/Main').default;
    renderApp(NextApp);
  });
}
unregister();

// registerServiceWorker();
