import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import ResizeDetector from 'react-resize-detector';

import AppMain from '../../Layout/AppMain';
import AppHeader from '../../Layout/AppHeader';
import AppSidebar from '../../Layout/AppSidebar';
import AppFooter from '../../Layout/AppFooter';

const Main = () => {
  const {
    colorScheme,
    closedSmallerSidebar,
    enableFixedHeader,
    enableMobileMenu,
    enableFixedFooter,
    enableFixedSidebar,
    enableClosedSidebar,
  } = useSelector((state) => state.ThemeOptions);

  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <div className={cx(
          `app-container app-theme-${colorScheme}`,
          { 'fixed-header': enableFixedHeader },
          { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
          { 'fixed-footer': enableFixedFooter },
          { 'closed-sidebar': enableClosedSidebar || width < 1250 },
          { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
          { 'sidebar-mobile-open': enableMobileMenu },
        )}
        >
          <AppHeader />
          <div className="app-main">
            <AppSidebar />
            <div className="app-main__outer">
              <div className="app-main__inner">
                <AppMain />
              </div>
              <AppFooter className="app-main__footer" />
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default Main;
