export const MainNav = [
  {
    icon: 'pe-7s-note2',
    label: 'COVID-19 VIỆT NAM',
    to: '#/dashboards',
  },
];
export const ComponentsNav = [
  {
    icon: 'pe-7s-user',
    label: 'Theo mã BN',
    content: [
      {
        label: 'Tra cứu lịch trình',
        to: '#/truy-vet/lich-trinh',
      },
      {
        label: 'Tra cứu mối quan hệ',
        to: '#/ban-do-quan-he',

      },
    ],
  },
  // {
  //   icon: 'pe-7s-map-marker',
  //   label: 'Theo vùng miền',
  //   content: [
  //     {
  //       label: 'Tra cứu khu vực',
  //       to: '#/components/tabs',
  //     },
  //     {
  //       label: 'Tra cứu tổng hợp',
  //       to: '#/components/notifications',
  //     },
  //   ],
  // },
  {
    icon: 'pe-7s-map-2',
    label: 'Map Tổng hợp',
    to: '#/map-tong-hop',
  },
];
export const HelpNav = [
  {
    icon: 'pe-7s-light',
    label: 'Giải đáp thắc mắc (Q&A)',
    to: 'https://moh.gov.vn/tin-tong-hop/-/asset_publisher/k206Q9qkZOqn/content/cam-nang-hoi-ap-thong-tin-ve-benh-viem-uong-ho-hap-cap-do-chung-moi-vi-rut-corona-ncov-',
    externalLink: true,
  },
  {
    icon: 'pe-7s-attention',
    label: 'Tài liệu Thông tin về COVID19',
    to: '#/ho-tro/trieu-chung',
  },
  {
    icon: 'pe-7s-chat',
    label: 'Khai báo F0/F1/F2',
    to: '#/lien-he-to-chong-dich',
  },
];
export const ReportNav = [
  {
    icon: 'pe-7s-note2',
    label: 'Tờ khai Y tế',
    to: '#/to-khai-y-te',
  },
];
export const NetworkChartNav = [
  {
    icon: 'pe-7s-share',
    label: 'Bản đồ quan hệ',
    to: '#/ban-do-quan-he',
  },
  {
    icon: 'pe-7s-share',
    label: 'Bản đồ quan hệ Bắc Giang',
    to: '#/bdqh-tinh/bac-giang',
  },
  {
    icon: 'pe-7s-share',
    label: 'Bản đồ quan hệ Bắc Ninh',
    to: '#/bdqh-tinh/bac-ninh',
  },
  {
    icon: 'pe-7s-share',
    label: 'Bản đồ quan hệ Hà Nội',
    to: '#/bdqh-tinh/ha-noi',
  },
];
