import Papa from 'papaparse';

export const getCsvData = async (filePath, options = {}) => {
  const response = await fetch(filePath);
  const reader = response.body.getReader();
  const result = await reader.read(); // raw array
  const decoder = new TextDecoder('utf-8');
  const csv = decoder.decode(result.value); // the csv text
  const results = Papa.parse(csv, { header: true, ...options }); // object with { data, errors, meta }
  const rows = results.data; // array of objects
  return rows;
};

export const getAbbreviate = (name) => name.split(' ').map((nameComponent) => nameComponent[0]).join('. ');
