import React, { Suspense, lazy, Fragment } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import {
  ToastContainer,
} from 'react-toastify';
import FullPageLoading from '../../components/common/FullPageLoading';

const Dashboards = lazy(() => import('../../DemoPages/Dashboards'));
const Components = lazy(() => import('../../DemoPages/Components'));
const HoTro = lazy(() => import('../../DemoPages/HoTro'));
const TruyVet = lazy(() => import('../../DemoPages/TruyVet'));
const ToKhaiYTe = lazy(() => import('../../DemoPages/ToKhaiYTe'));
const BanDoQuanHe = lazy(() => import('../../DemoPages/BanDoQuanHe'));
const BanDoQuanHeTinh = lazy(() => import('../../DemoPages/BanDoQuanHeTinh'));
const LienHeToChongDich = lazy(() => import('../../DemoPages/LienHeToChongDich'));
const MapTongHop = lazy(() => import('../../DemoPages/MapTongHop'));

const SuspenseRoute = (props) => (
  <Suspense fallback={<FullPageLoading loading />}>
    <Route path={props.path} component={props.component} />
  </Suspense>
);

const AppMain = () => (
  <>
    <Switch>
      <SuspenseRoute path="/dashboards" component={Dashboards} />
      <SuspenseRoute path="/components" component={Components} />
      <SuspenseRoute path="/truy-vet" component={TruyVet} />
      <SuspenseRoute path="/ho-tro" component={HoTro} />
      <SuspenseRoute path="/to-khai-y-te" component={ToKhaiYTe} />
      <SuspenseRoute path="/ban-do-quan-he" component={BanDoQuanHe} />
      <SuspenseRoute path="/bdqh-tinh" component={BanDoQuanHeTinh} />
      <SuspenseRoute path="/lien-he-to-chong-dich" component={LienHeToChongDich} />
      <SuspenseRoute path="/map-tong-hop" component={MapTongHop} />
      <Route
        exact
        path="/"
        render={() => (
          <Redirect to="/dashboards" />
        )}
      />
    </Switch>
    <ToastContainer />
  </>
);

export default AppMain;
