import React from 'react';
import classNames from 'classnames';

const AppFooter = ({ className }) => (
  <span
    className={classNames(className, 'w-100 text-center py-1')}
    style={{
      backgroundColor: '#1565C0',
      color: 'white',
    }}
  >
    Dữ liệu được xử lí và cập nhật bởi
    {' '}
    <strong>Viện Vệ sinh Dịch tễ Trung Ương</strong>
    {' '}
    thuộc
    Bộ Y tế và
    {' '}
    <strong>Tổ thông tin đáp ứng nhanh</strong>
    {' '}
    thuộc BCĐQG Phòng,
    chống dịch COVID-19.
  </span>
);

export default AppFooter;
