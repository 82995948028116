import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

const FullPageLoading = ({ loading }) => (
  <>
    {loading && (
      <div
        className="d-flex w-100 h-100 align-items-center justify-content-center position-absolute"
        style={{
          zIndex: '1000',
          top: '0',
          left: '0',
          backgroundColor: 'rgb(192, 192, 192, 0.5)',
        }}
      >
        <Spinner
          animation="border"
          variant="primary"
          style={{
            width: '10rem',
            height: '10rem',
          }}
        />
      </div>
    )}
  </>
);

FullPageLoading.propTypes = {
  loading: PropTypes.bool,
};

FullPageLoading.defaultProps = {
  loading: false,
};

export default FullPageLoading;
