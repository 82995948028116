import React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import HeaderLogo from '../AppLogo';

const Header = () => {
  const {
    headerBackgroundColor,
    enableMobileMenuSmall,
    enableHeaderShadow,
  } = useSelector((state) => state.ThemeOptions);

  return (
    <>
      <ReactCSSTransitionGroup
        component="div"
        className={cx('app-header', headerBackgroundColor, { 'header-shadow': enableHeaderShadow })}
        transitionName="HeaderAnimation"
        transitionAppear
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >

        <HeaderLogo />
        <div className={cx(
          'app-header__content',
          { 'header-mobile-open': enableMobileMenuSmall },
        )}
        >
          <div className="app-header-left" />
          <div className="app-header-right">
            <div className="text-white fsize-1 bold px-5 strong">TỔ THÔNG TIN ĐÁP ỨNG NHANH BCĐQG PC DỊCH COVID-19 | VIỆN VỆ SINH DỊCH TỄ TRUNG ƯƠNG</div>
          </div>
        </div>

      </ReactCSSTransitionGroup>
    </>
  );
};

export default Header;
