import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Hamburger from 'react-hamburgers';
import AppMobileMenu from '../AppMobileMenu';
import { setEnableClosedSidebar } from '../../reducers/ThemeOptions';

const HeaderLogo = () => {
  const [state, setState] = useState({
    active: false,
    mobile: false,
    activeSecondaryMenuMobile: false,
  });

  const { enableClosedSidebar } = useSelector((reducerState) => reducerState.ThemeOptions);

  const dispatch = useDispatch();

  const toggleEnableClosedSidebar = () => {
    dispatch(setEnableClosedSidebar(!enableClosedSidebar));
  };

  return (
    <>
      <div className="app-header__logo">
        <div className="logo-src" />
        <div className="header__pane ml-auto">
          <div onClick={toggleEnableClosedSidebar}>
            <Hamburger
              active={enableClosedSidebar}
              type="elastic"
              onClick={() => setState({
                ...state,
                active: !state.active,
              })}
            />
          </div>
        </div>
      </div>
      <AppMobileMenu />
    </>
  );
};

export default HeaderLogo;
