import React, { useEffect, useState } from "react";
import { NetworkData } from "../../apis/data";
import FullPageLoading from "../common/FullPageLoading";

export const NetworkDataProvider = (props) => {
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    (async () => {
      await NetworkData.getData();
      setLoading(false);
    })();
  }, []);
  
  return loading ? <FullPageLoading/> : props.children;
};