import React from 'react';
import { useLocation } from 'react-router-dom';

import MetisMenu from 'react-metismenu';

import {
  MainNav, ComponentsNav, HelpNav, ReportNav, NetworkChartNav,
} from './NavItems';

const Nav = () => {
  const location = useLocation();

  return (
    <React.Fragment key={location.pathname}>
      <div>
        <h5 className="app-sidebar__heading">Trang Chủ</h5>
        <MetisMenu
          activeLinkFromLocation
          content={MainNav}
          className="vertical-nav-menu"
          classNameStateIcon="pe-7s-angle-down"
          iconNamePrefix=""
        />
        <h5 className="app-sidebar__heading">Bản đồ Truy vết</h5>
        <MetisMenu
          activeLinkFromLocation
          content={ComponentsNav}
          className="vertical-nav-menu"
          classNameStateIcon="pe-7s-angle-down"
          iconNamePrefix=""
        />
        <h5 className="app-sidebar__heading">Hỗ trợ 24/7</h5>
        <MetisMenu
          activeLinkFromLocation
          content={HelpNav}
          className="vertical-nav-menu"
          classNameStateIcon="pe-7s-angle-down"
          iconNamePrefix=""
        />
        <h5 className="app-sidebar__heading">Khai báo Y tế</h5>
        <MetisMenu
          activeLinkFromLocation
          content={ReportNav}
          className="vertical-nav-menu"
          classNameStateIcon="pe-7s-angle-down"
          iconNamePrefix=""
        />
        <h5 className="app-sidebar__heading">Thống kê khác</h5>
        <MetisMenu
          content={NetworkChartNav}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          classNameStateIcon="pe-7s-angle-down"
        />
      </div>
      <h6 className="text-white text-muted opacity-6 fsize-1 mt-auto">Powered by Minh Ta & Hai Nguyen @2021</h6>
    </React.Fragment>
  );
};

export default Nav;
