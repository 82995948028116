import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Hamburger from 'react-hamburgers';
import cx from 'classnames';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import {
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
} from '../../reducers/ThemeOptions';

const AppMobileMenu = () => {
  const [state, setState] = useState({
    active: false,
    mobile: false,
    activeSecondaryMenuMobile: false,
  });
  const {
    enableMobileMenu,
    enableMobileMenuSmall,
  } = useSelector((reducerState) => reducerState.ThemeOptions);
  const dispatch = useDispatch();

  const toggleMobileSidebar = () => {
    dispatch(setEnableMobileMenu(!enableMobileMenu));
  };

  const toggleMobileSmall = () => {
    dispatch(setEnableMobileMenuSmall(!enableMobileMenuSmall));
  };

  return (
    <>
      <div className="app-header__mobile-menu">
        <div onClick={toggleMobileSidebar}>
          <Hamburger
            active={enableMobileMenu}
            type="elastic"
            onClick={() => setState({
              ...state,
              activeMobile: !state.activeMobile,
            })}
          />
        </div>
      </div>
      <div className="app-header__menu">
        <span onClick={toggleMobileSmall}>
          <Button
            size="sm"
            className={cx('btn-icon btn-icon-only', { active: state.activeSecondaryMenuMobile })}
            color="primary"
            onClick={() => setState({
              ...state,
              activeSecondaryMenuMobile: !state.activeSecondaryMenuMobile,
            })}
          >
            <div className="btn-icon-wrapper"><FontAwesomeIcon icon={faEllipsisV} /></div>
          </Button>
        </span>
      </div>
    </>
  );
};

export default AppMobileMenu;
